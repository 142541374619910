import { useEffect } from 'react';
import type { AppProps } from 'next/app';

import AppProvider from 'components/AppProvider';
import Modal from 'react-modal';
import Nav from 'components/Nav';
import recordConversion from 'lib/recordConversion';
import Script from 'next/script';

import 'styles/index.scss';

Modal.setAppElement('#Modal');

function findAncestor(el: HTMLElement | null, nodeName: string) {
  while ((el = el && el.parentElement) && el.nodeName !== nodeName);
  return el;
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.document.addEventListener('click', function (e) {
        if (e.target instanceof HTMLElement) {
          const closestAHref = findAncestor(e.target, 'A');

          if (
            (e.target && e.target.nodeName === 'A') ||
            (closestAHref &&
              closestAHref.toString().includes('hello@hydraulics.nyc'))
          ) {
            recordConversion();
          }
        }
        return true;
      });

      /*
      We iterate through every hash link. These are in the nav, to link to
      different sections on the homepage.
      */
      window.document.querySelectorAll('a[href^="/#"]').forEach((anchor) => {
        /*
        We override the default click functionality.
        */
        anchor.addEventListener('click', (e) => {
          /*
          If we're not on the homepage, abort. We don't want
          to override the default Next/link functionality.
          */
          if (window.location.pathname !== '/') return;

          /*
          If we are on the hompage, prevent default linking and replace
          with a smooth scroll. Use the href property on the link to deteremine
          where to link to.
          */
          e.preventDefault();
          const href = anchor.getAttribute('href');
          if (href) {
            const hrefWithoutSlash = href.startsWith('/')
              ? href.split('/')[1]
              : href;
            window?.document?.querySelector(hrefWithoutSlash)?.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      });
    }
  }, []);

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-557434647" />
      <Script id="google-pixel">
        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-557434647');`}
      </Script>
      <Script id="linkedin-pixel">
        {`_linkedin_partner_id = "4821530";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </Script>
      <AppProvider globals={pageProps.globals}>
        <Component {...pageProps} />
        <div id="Modal" />
      </AppProvider>
    </>
  );
}

export default MyApp;
