import { createContext, FC } from 'react';

import * as Types from 'lib/sanity/types';

export const SettingsContext = createContext<Types.Settings | undefined>(
  undefined,
);

interface SettingsContextProviderProps {
  children: React.ReactNode;
  settings?: Types.Settings;
}

export const SettingsProvider: FC<SettingsContextProviderProps> = ({
  settings,
  children,
}) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};
