import { FC, PropsWithChildren } from 'react';
import { UIContextProvider } from 'contexts/ui';
import { SettingsProvider } from 'contexts/settings';

import * as Types from 'lib/sanity/types';

type Props = PropsWithChildren<{ globals: Types.Globals }>;

const AppProvider: FC<Props> = ({ children, globals }) => {
  return (
    <SettingsProvider settings={globals.settings}>
      <UIContextProvider>{children}</UIContextProvider>
    </SettingsProvider>
  );
};

export default AppProvider;
