export default function recordConversion() {
  console.log('Will fire conversion events!');
  // @ts-ignore
  window.gtag &&
    // @ts-ignore
    window.gtag('event', 'conversion', {
      send_to: 'AW-557434647/a3slCPK2j5UYEJeO54kC',
    });
  // @ts-ignore
  window.lintrk &&
    // @ts-ignore
    window.lintrk('track', { conversion_id: 12123266 });
}
