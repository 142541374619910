import React, {
  FC,
  useState,
  useContext as reactUseContext,
  useRef,
} from 'react';
import Modal from 'react-modal';

const MODAL_STYLES = {
  overlay: {
    zIndex: '30',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    background: 'none',
  },
};

interface UIContextProps {
  clientHeight: number;
  clientWidth: number;
  setClientHeight: (height: number) => void;
  setClientWidth: (height: number) => void;
  setModalContent: (content: React.ReactNode | null) => void;
  showCalendly: boolean;
  setShowCalendly: (show: boolean) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  autoPlay: boolean;
  setAutoPlay: (autoPlay: boolean) => void;
  isVideoPaused: boolean;
  setIsVideoPaused: (isVideoPaused: boolean) => void;
  handlePlayVideo: () => void;
}

export const UIContext = React.createContext<UIContextProps>({
  clientHeight: 0,
  clientWidth: 0,
  setClientHeight: (height: number) => {},
  setClientWidth: (width: number) => {},
  setModalContent: (content: React.ReactNode | null) => {},
  showCalendly: false,
  setShowCalendly: (show: boolean) => {},
  videoRef: React.createRef<HTMLVideoElement>(),
  autoPlay: true,
  setAutoPlay: (autoPlay: boolean) => {},
  isVideoPaused: false,
  setIsVideoPaused: (isVideoPaused: boolean) => {},
  handlePlayVideo: () => {},
});

interface UIContextProviderProps {
  children: React.ReactNode;
}

export const UIContextProvider: FC<UIContextProviderProps> = ({ children }) => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null,
  );

  // Global state for showing Calendly in the Contact US modal
  const [showCalendly, setShowCalendly] = useState<boolean>(false);

  // Global states for playing/pausing videos
  const videoRef = useRef<HTMLVideoElement>(null);
  const [autoPlay, setAutoPlay] = useState(true);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const handlePlayVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsVideoPaused(false);
      } else {
        videoRef.current.pause();
        setIsVideoPaused(true);
      }
    }
  };

  return (
    <UIContext.Provider
      value={{
        clientHeight: height,
        clientWidth: width,
        setClientHeight: setHeight,
        setClientWidth: setWidth,
        setModalContent: setModalContent,
        showCalendly: showCalendly,
        setShowCalendly: setShowCalendly,
        videoRef: videoRef,
        autoPlay: autoPlay,
        setAutoPlay: setAutoPlay,
        isVideoPaused: isVideoPaused,
        setIsVideoPaused: setIsVideoPaused,
        handlePlayVideo: handlePlayVideo,
      }}
    >
      {children}
      <Modal
        isOpen={!!modalContent}
        onRequestClose={() => setModalContent(null)}
        style={MODAL_STYLES}
        contentLabel="Modal"
      >
        {modalContent}
      </Modal>
    </UIContext.Provider>
  );
};

export const useContext = () => reactUseContext(UIContext);
